import React from 'react';
import Layout from 'layout';
import { ExtendGridBox } from 'pages/integrations/analytics';
import SideBar from 'components/marketplace/sideBar';
import Content from 'components/marketplace/content';
import Banner from 'components/marketplace/banner';
import { frontmatter } from 'data/marketplace/integrations';
import arrow from 'img/ui/arrow_black.svg';
import OwnAppSection from 'components/marketplace/ownAppSection';

const BugTracking = () => {
  const [mostPopularCard] = frontmatter.items.filter((item) => item.title === 'Sentry');
  return (
    <Layout
      metaTitle="Bug tracking | Integrations"
      canonical="/integrations/bug-tracking/"
      metaDescription="See what caused the error on your website. LiveSession makes debugging easier."
    >
      <div className="container">
        <ExtendGridBox>
          <div className="breadcrumbs">
            <p>
              <a href="/integrations/">Integrations</a>
            </p>
            <img src={arrow} alt="arrow" title="Arrow" />
            <p>
              <a href="/integrations/bug-tracking/">
                <strong>Bug tracking</strong>
              </a>
            </p>
          </div>
          <SideBar activeCategory="Bug tracking" underlineColor="#FFE478" />
          <Content activeCategory="Bug tracking" hideTitle>
            <Banner
              activeCategory="Bug tracking"
              card={mostPopularCard}
              text="See what caused the error on your website. LiveSession makes debugging easier."
              underlineColor="#FFE478"
            />
          </Content>
        </ExtendGridBox>
        <OwnAppSection />
      </div>
    </Layout>
  );
};

export default BugTracking;
